/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import type { InstanceWithExtensions, SDKBase } from '@magic-sdk/provider';

import type { MagicSDKAdditionalConfiguration, MagicUserMetadata } from 'magic-sdk';
import { Magic } from 'magic-sdk';
import Web3 from 'web3';
import { OAuthExtension, OAuthRedirectResult } from '@magic-ext/oauth';
import { RPC } from './web3.service';
import { TxParams } from './tx-params.type';
import { RegisteredSubscription } from 'web3-eth';

export interface AuthContextType<TUser = any> {
  setAuthState: (state: 'IDLE' | 'LOGGED' | 'UNLOGGED' | 'LOADING') => void;
  authState: 'IDLE' | 'LOGGED' | 'UNLOGGED' | 'LOADING';
  login: (
    provider:
      | 'email_passwordless'
      | 'google'
      | 'twitter'
      | 'linkedin'
      | 'facebook'
      | 'discord'
      | 'apple',
    loginhint?: string
  ) => Promise<(MagicUserMetadata & { didToken: string }) | undefined>;
  logout: () => Promise<void>;
  redirectHandler: () => Promise<OAuthRedirectResult | undefined>;
  address: string | null;
  balance: number | null;
  userInfo: TUser | null;
  estimateNetworkFee: ({ to, value }: { to: string; value: string }) => Promise<string>;
  sendTransaction: (payload: TxParams) => Promise<string>;
  isTransactionLoading: boolean;
  isTransactionConfirmed: boolean;
  setUser: React.Dispatch<React.SetStateAction<TUser | null>>;
  loginError: string | null;
  setLoginError: React.Dispatch<React.SetStateAction<string | null>>;
  fromWeiToEth: (value: string) => Promise<string>;
  fromEthToGWei: (value: string) => string;
  getGasCost: () => Promise<number>;
  sendTransactionAndWait: (payload: TxParams) => Promise<string>;
}

export const AuthContext = React.createContext<AuthContextType<any>>({} as AuthContextType<any>);

export function useAuth<T>() {
  return React.useContext(AuthContext as React.Context<AuthContextType<T>>);
}

type AuthProviderProps = {
  apiKey: string;
  config: MagicSDKAdditionalConfiguration<string, OAuthExtension[]> | undefined;
  children: React.ReactNode;
};
let m: InstanceWithExtensions<SDKBase, OAuthExtension[]> | null = null;
let web3Provider: Web3 | null = null;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, apiKey, config }) => {
  const [isTransactionLoading, setIsTransactionLoading] = React.useState(false);
  const [isTransactionConfirmed, setIsTransactionConfirmed] = React.useState(false);
  const [userInfo, setUser] = React.useState<any | null>(null);
  const [loginError, setLoginError] = React.useState<string | null>(null);
  const [balance, setBalance] = React.useState<number | null>(null);
  const [address, setAddress] = React.useState<string | null>(null);
  const [authState, setAuthState] = React.useState<'IDLE' | 'LOGGED' | 'UNLOGGED' | 'LOADING'>(
    'IDLE'
  );
  async function loginWithEmail(loginhint?: string) {
    setAuthState('LOADING');
    if (m === null) {
      return;
    }
    const didToken = await m.auth.loginWithEmailOTP({ email: loginhint ?? '' });
    if (!didToken) {
      setAuthState('UNLOGGED');
      return;
    }
    setAuthState('LOGGED');
    const userInfo = await m.user.getInfo();
    return { ...userInfo, didToken };
  }

  async function loginWithRedirect(
    provider: 'google' | 'twitter' | 'linkedin' | 'facebook' | 'discord' | 'apple'
  ) {
    setAuthState('LOADING');
    if (m === null) {
      return;
    }

    await m.oauth.loginWithRedirect({
      provider,
      redirectURI: new URL('/auth-callback', window.location.origin).href
    });
    localStorage.setItem('redirected', 'true');

    return undefined;
  }
  async function login(
    provider:
      | 'email_passwordless'
      | 'google'
      | 'twitter'
      | 'linkedin'
      | 'facebook'
      | 'discord'
      | 'apple',
    loginhint?: string
  ): Promise<(MagicUserMetadata & { didToken: string }) | undefined> {
    if (m === null) {
      return;
    }

    if (authState !== 'UNLOGGED' && authState !== 'IDLE') {
      return;
    }

    let action: Promise<(MagicUserMetadata & { didToken: string }) | undefined>;
    if (provider === 'email_passwordless') {
      action = loginWithEmail(loginhint);
    } else {
      action = loginWithRedirect(provider);
    }

    const didToken = await action;

    return didToken;
  }

  async function logout(): Promise<void> {
    if (m === null) {
      return;
    }
    setAuthState('LOADING');
    await m.user.logout();
    setAuthState('UNLOGGED');
  }

  function sleep(ms: number) {
	  return new Promise(resolve => setTimeout(resolve, ms));
  }

  function getWeb3(): Web3<RegisteredSubscription> {
    web3Provider = new Web3(m?.rpcProvider as any);
    if(web3Provider.currentProvider) {
	    web3Provider.currentProvider.request = new Proxy(web3Provider.currentProvider.request, {
		    apply: async (target, thisArg, args) => {
			    console.debug('[REQUEST_MIDDLEWARE] Call', ...args)    
			    const retries = 5;
			    const delayTime = 1000;
			    for (let attempt = 1; attempt <= retries; attempt++) {
				    try {
					    return await target.apply(thisArg, args as any);
				    } catch (error: any) {
					    console.error('[REQUEST_MIDDLEWARE] Error', error)
					    if ((error.cause.code === 429 || error.code === 429) && attempt < retries) {
						    const backoffTime = delayTime * Math.pow(2, attempt - 1);
						    console.log(`Rate limited (429). Retrying in ${backoffTime} ms... (Attempt ${attempt})`);
						    await sleep(backoffTime);
					    } else {
						    throw error;
					    }
				    }
			    }
			    throw new Error('Max retries reached. Request failed.');
		    }
	    });
    }

    return web3Provider
  }

  function initMagic() {
    m = new Magic(apiKey, config);
    web3Provider = getWeb3()
  }

  async function figureOutAuthState() {
    initMagic();

    if (authState === 'IDLE' && m !== null) {
      setAuthState('LOADING');
      const loggedIn = await m.user.isLoggedIn();
      if (loggedIn) {
        setAuthState('LOGGED');
        return;
      }
      setAuthState('UNLOGGED');
    }
  }

  async function handleLoggedState() {
    if (authState === 'LOGGED' && m !== null) {
      if (web3Provider !== null) {
        const address = (await web3Provider.eth.getAccounts())[0];
        setAddress(address);

        const balance = web3Provider.utils.fromWei(
          await web3Provider.eth.getBalance(address),
          'ether'
        );
        setBalance(Number(balance));
      }
    }
  }

  async function handleUnloggedState() {
    if (authState === 'UNLOGGED') {
      setUser(null);
      setBalance(null);
    }
  }

  async function redirectHandler() {
    initMagic();
    if (m !== null) {
      setAuthState('LOADING');
      try {
        const result = await m.oauth.getRedirectResult();
        console.log(result);
        localStorage.removeItem('redirected');

        return result;
      } catch (e) {
        console.log(e);
        localStorage.removeItem('redirected');
        setAuthState('UNLOGGED');
      }
    }
  }

  const checkTx = (hash: string) =>
    new Promise<any>((resolve) => {
      const interval = setInterval(async () => {
        web3Provider = getWeb3()
        const receipt = await web3Provider.eth.getTransactionReceipt(hash);
        if (receipt) {
          resolve(receipt);
          clearInterval(interval);
          setIsTransactionLoading(false);
          setIsTransactionConfirmed(true);
        }
      }, 1000);
    });

  const checkProvider = async (cb: Function) => {
    if (!web3Provider) {
      console.log('provider not initialized yet');
      return;
    }

    const rpc = new RPC(web3Provider);

    return cb(rpc);
  };

  const getBalance = async (rpc: RPC) => {
    const userBalance = await rpc.getBalance();

    return userBalance;
  };

  const fromWeiToEth = async (value: string) => {
    web3Provider = getWeb3()
    return web3Provider.utils.fromWei(value, 'ether');
  };

  const fromEthToGWei = (value: string) => (parseFloat(value) * 1000000000).toString();
  const estimateNetworkFee = async ({ to, value }: { to: string; value: string }) => {
    web3Provider = getWeb3()
    try {
      const gasPrice = await web3Provider.eth.getGasPrice();
      const from = (await web3Provider.eth.getAccounts())[0];
      const txData = {
        from,
        to,
        gasPrice,
        value: web3Provider.utils.toWei(value, 'ether')
      };
      const gasLimit = await web3Provider.eth.estimateGas(txData);
      const networkFee = gasLimit * gasPrice;
      return web3Provider.utils.fromWei(networkFee, 'ether');
    } catch (e: any) {
      throw new Error('Insufficient funds');
    }
  };

  const sendTransaction = async ({ gas, ...rest }: TxParams): Promise<string> => {
    web3Provider = getWeb3()
    const from = (await web3Provider.eth.getAccounts())[0];
    const txParams = {
      from,
      gas: String(gas),
      ...rest
    };
    setIsTransactionLoading(true);
    setIsTransactionConfirmed(false);
    const transactionHash = await new Promise<string>((res, rej) => {
      web3Provider = getWeb3()
      web3Provider.eth
        .sendTransaction(txParams)
        .on('transactionHash', (hash) => {
          res(hash);
        })
        .on('error', (err) => {
          console.log('JSON RPC: ', err.message);
          setIsTransactionLoading(false);
          rej(err);
        });
    });
    setIsTransactionLoading(false);
    checkTx(transactionHash).then(() =>
      checkProvider(getBalance).then((value) => setBalance(value))
    );

    return transactionHash;
  };

  const sendTransactionAndWait = async ({ gas, ...rest }: TxParams): Promise<string> => {
    web3Provider = getWeb3()
    const from = (await web3Provider.eth.getAccounts())[0];
    const txParams = {
      from,
      gas: String(gas),
      ...rest
    };
    setIsTransactionLoading(true);
    const transactionHash = await new Promise<string>((res, rej) => {
      web3Provider = getWeb3()
      web3Provider.eth
        .sendTransaction(txParams)
        .on('transactionHash', (hash) => {
          res(hash);
        })
        .on('error', (err) => {
          console.log('JSON RPC: ', err.message);
          setIsTransactionLoading(false);
          rej(err);
        });
    });
    await checkTx(transactionHash);
    setIsTransactionLoading(false);

    return transactionHash;
  };

  const getGasCost = async (gasLimit: number = 21000) => {
    web3Provider = getWeb3()
    const gasPrice = await web3Provider.eth.getGasPrice();
    const gasCost = gasPrice * BigInt(gasLimit);

    const gasCostInMatic = web3Provider.utils.fromWei(gasCost.toString(), 'ether');

    return parseFloat(gasCostInMatic) * 1.1; // 10% of gas cost buffer jic
  };

  React.useEffect(() => {
    if (window.location.pathname !== '/auth-callback' && localStorage.getItem('redirected')) {
      localStorage.removeItem('redirected');
    }
    if (!localStorage.getItem('redirected')) {
      figureOutAuthState();
      handleLoggedState();
      handleUnloggedState();
    }

    console.log({ authState });
  }, [authState]);

  const memoedData = React.useMemo(
    () => ({
      userInfo,
      balance,
      authState,
      setAuthState,
      login,
      logout,
      redirectHandler,
      isTransactionLoading,
      setIsTransactionLoading,
      isTransactionConfirmed,
      setIsTransactionConfirmed,
      loginError,
      setLoginError,
      estimateNetworkFee,
      sendTransaction,
      fromWeiToEth,
      fromEthToGWei,
      sendTransactionAndWait,
      getGasCost,
      address,
      setAddress,
      setUser
    }),
    [
      userInfo,
      balance,
      authState,
      loginError,
      isTransactionLoading,
      isTransactionConfirmed,
      address
    ]
  );
  return <AuthContext.Provider value={memoedData}>{children}</AuthContext.Provider>;
};
